.CardBody {
    display: flex;
    width: 100%;
    border-radius: 30px;
    /* padding: 12px 0; */
}

.card-heading {
    color: #000;
    font-size: 29px;
    font-weight: 1000;
}

.card-text {
    color: #8b8b8b;
    font-weight: 600;
    font-size: 13px;
    margin: 12px 15px;
    width: 95%;
    padding: 0 12px 0 4px;
}

.card-para {
    font-size: 16px;
}

.doctor-corner-img {
    width: 55%;
    height: 200px;
}

.mainDiv {
    width: 80%;
}
.no-underline{
    text-decoration: none !important;
}

.side-nav {
    width: 100%;
    height: auto;
    border-radius: 15px;
    background-color: #fff;
    margin: 16px 0;
}

.side-nav-heading {
    padding: 8px 22px;
    position: relative;
    font-weight: 600;
}

.underline {
    position: absolute;
    width: 120px;
    height: 2px;
    margin-left: 1px;
    border-bottom: 2px solid black;
}

.list-nav {
    list-style: none;
    font-size: 13px;
    color: #8b8b8b;
    font-weight: 600;
    padding: 12px 22px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s ease;
}
.list-nav:hover{
    color: #1da827;
}

/* .list-nav:nth-child(1) {
    color: #000;
    font-weight: 800;
} */

.bannerBody {
    height: 108px;
    width: 100%;
    max-width: 100vw;
    background-color: #9cddf8;
}
/* hllo world for testing in css */
.bannerHeading {
    color: white;
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    /* padding: 20px 0;  */
}

.redirect-link {
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.redirect-link:hover {
    border-bottom: 2px solid #1da827;
    color: #1da827 !important;
}


/* Css for doctors page */
@media (max-width:600px) {


    .CardBody {
        flex-direction: column;
        width: 126%;
    }

    .doctor-corner-img {
        width: 100%;
    }

    .card-heading {
        font-size: 22px;
        color: #1e335f;
    }

    .card-para {
        font-size: 12px;
    }

    .card-text {
        padding-bottom: 16px;
    }

    .bannerHeading{
        font-size: 22px;
        /* padding: 40px 0; */
    }
    .mainDiv{
        width: 80%;
    }
}

.doctor-corner-skeleton {
/* General skeleton styles */
.skeleton-img, .skeleton-heading, .skeleton-para, .skeleton-link {
  background-color: #e0e0e0; /* Light gray */
  border-radius: 4px;
  animation: shimmer 1.5s infinite;
}

/* Image skeleton */
.skeleton-img {
  width: 55%;
  height: 200px; /* Adjust to your image size */
}

/* Text skeletons */
.skeleton-heading {
  width: 60%;
  height: 24px;
  margin: 16px 0;
}

.skeleton-para {
  width: 90%;
  height: 16px;
  margin-bottom: 12px;
}

.skeleton-link {
  width: 30%;
  height: 16px;
}

/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
}