.bannerBody {
    height: 108px;
    width: 100%;
    max-width: 100vw;
    background-color: #9cddf8;
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bannerHeading {
    color: white;
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    /* padding: 20px 0;  */
}
.subBannerHeading{
    font-size: 12px;
    color: #4065af;
    cursor: pointer;
}
.doc-detail-para{
    width: 80% ;
}

.cardBox{
    width: 270px;   
}
.cardTitle{
    font-size: 17px;
    color: #4065af;
    text-align: center;
}
@media (max-width:600px){

    .doc-detail-para{
        width: 100%;
    }
    .bannerHeading{
        font-size: 22px;
        /* padding: 40px 0; */
    }
} 