.container {
  max-width: 90%;
  width: 1353px;
  margin: auto;
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 95%;
  }
}

.navbar-brand img {
  /* border: 1px solid #000; */
  /* border-radius: 4px; */
}

.counter-section {
  background: linear-gradient(to right, rgb(10, 86, 164), rgb(160, 219, 151));
  padding: 5px 0px;
}

.counter-section .blink-soft {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.counter-section span {
  font-size: 13px;
}

.counter-section p {
  text-align: center;
  color: #b50d0d;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.counter-col {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #fff;
  gap: 11px;
}

.hero-banner {
  position: relative;
  padding-bottom: 30px;
  border-top: 1px solid #dddddd;
}

.hero-banner .swiper {
  position: inherit;
}

.hero-banner .swiper .swiper-button-prev {
  background-image: url(../../image/angle-left-solid.svg);
  background-position: center;
  height: 38px;
  width: 38px;
  background-size: 12px;
  left: 1% !important;
}

/* .hero-banner .swiper .swiper-button-prev::after {
  font-size: 0px;
} */

.hero-banner .swiper .swiper-button-next {
  /* background-image: url(../images/right-arrow.svg); */
  background-position: center;
  height: 38px;
  width: 38px;
  background-size: 12px;
  right: 1% !important;
}

.hero-banner .swiper .swiper-button-next::after {
  font-size: 0px;
}

.hero-banner .swiper-pagination-bullet {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  /* opacity: 10%; */
  /* background-color: #1da827; */
  /* background-color: rgba(255, 255, 255, 0.25882352941176473);
  border: 2px solid #ffffff; */
}

.hero-banner .swiper-pagination-bullet-active {
  width: 30px;
  height: 10px;
  border-radius: 8px;
  background-color: #6bb7d7;
  border: 0px;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 30px;
  background-repeat: no-repeat;
  background-color: #fff;
}

.swiper-button-next::after {
  font-size: 0px;
}

/* search-swiper-slider */
.search-swiper-slider {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 30px;
}

.search-swiper-slider a {
  color: #000;
  text-decoration: none;
}

.search-swiper-slider .search-by-heading {
  margin-bottom: 15px;
}

.search-swiper-slider .card {
  border: 0px;
  border-radius: 0px;
}

.search-swiper-slider .card img {
  border-radius: 15px;
  object-fit: cover;
  height: 180px;
  width: 200px;
}

.search-swiper-slider .card .card-body {
  padding-bottom: 0px;
}

.search-swiper-slider .card .card-body h5 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.search-swiper-slider h3 {
  font-size: 26px;
  font-style: normal;
  letter-spacing: 1.56px;
  color: black;
  font-weight: 600;
}

/* .savings-jodi-swiper */

.savings-jodi-swiper {
  padding: 30px 0px;
  position: relative;
}

.savings-jodi-swiper .swiper .swiper-button-prev {
  left: 10% !important;
}

.savings-jodi-swiper .swiper .swiper-button-next {
  right: 10% !important;
}

.savings-jodi-swiper .saving-heading {
  margin-bottom: 15px;
}

.savings-jodi-swiper .saving-heading h3 {
  margin-bottom: 0px;
}

.savings-jodi-swiper a {
  text-decoration: none;
}

.savings-jodi-swiper .card {
  background-color: rgb(247, 247, 247);
  border: 0px;
  border-radius: 0px;
}

.savings-jodi-swiper .card .card-body {
  text-align: left;
  color: #000;
}

.savings-jodi-swiper .card .card-body h6 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0px 0px 0px #000;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  display: flex;
  min-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 16px;
  line-height: 19px;
}

.savings-jodi-swiper .saving-card-img {
  height: 295px;
}

.savings-jodi-swiper .saving-card-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.savings-jodi-swiper .card .card-body ins {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 0px;
  text-decoration: none;
}

.savings-jodi-swiper .card .card-body del {
  color: #555555;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.savings-jodi-swiper .card .card-body p {
  font-weight: 400;
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 5px;
}

.savings-jodi-swiper .card .card-body p span {
  background-color: #1da827;
  color: white;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 400;
}

.savings-jodi-swiper .card .card-body .btn-primary {
  background-color: #6bb7d7;
  border: none;
  color: #fff;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.8;
  padding: 10px;
  border-radius: 0px;
}

@media screen and (max-width: 1440px) {
  .savings-jodi-swiper .swiper .swiper-button-prev {
    left: 0% !important;
  }

  .savings-jodi-swiper .swiper .swiper-button-next {
    right: 0% !important;
  }
}

@media screen and (max-width: 1200px) {
  .savings-jodi-swiper .swiper .swiper-button-prev {
    left: 2% !important;
  }

  .savings-jodi-swiper .swiper .swiper-button-next {
    right: 2% !important;
  }
}

.popular-pick-swiper {
  background-color: #f7f7f7;
  padding: 30px 0px;
  position: relative;
}

.popular-pick-swiper .swiper .swiper-button-prev {
  left: 10% !important;
}

.popular-pick-swiper .swiper .swiper-button-next {
  right: 10% !important;
}

.popular-pick-swiper .popular-pick {
  height: 280px;
}

.popular-pick-swiper .popular-pick img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.popular-pick-swiper .popular-heading {
  margin-bottom: 15px;
}

.popular-pick-swiper a {
  text-decoration: none;
}

.popular-pick-swiper .card {
  background-color: #fff;
  border: 0px;
  border-radius: 0px;
  /* height: 100%;
  display: flex;
  height: 100%;
  align-items: stretch; */
}

.popular-pick-swiper .card .card-body {
  text-align: left;
  color: #000;
  /* display: flex;
  flex-direction: column; */
}

.popular-pick-swiper .card .card-body h6 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0px 0px 0px #000;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  display: flex;
  min-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 16px;
  line-height: 19px;
}

.popular-pick-swiper .card .card-body ins {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 0px;
  text-decoration: none;
}

.popular-pick-swiper .card .card-body del {
  color: #555555;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.popular-pick-swiper .card .card-body p {
  font-weight: 400;
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 5px;
}

.popular-pick-swiper .card .card-body p span {
  background-color: #1da827;
  color: white;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 400;
}

.popular-pick-swiper .card .card-body .btn-primary {
  background-color: #6bb7d7;
  border: none;
  color: #fff;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.8;
  padding: 10px;
  border-radius: 0px;
}

@media screen and (max-width: 1440px) {
  .popular-pick-swiper .swiper .swiper-button-prev {
    left: 0% !important;
  }

  .popular-pick-swiper .swiper .swiper-button-next {
    right: 0% !important;
  }
}

@media screen and (max-width: 1200px) {
  .popular-pick-swiper .swiper .swiper-button-prev {
    left: 2% !important;
  }

  .popular-pick-swiper .swiper .swiper-button-next {
    right: 2% !important;
  }
}

.our-product-section {
  padding: 30px 0px;
  background-color: #f7f7f7;
}

.our-product-section a {
  text-decoration: none;
}

.our-product-section .product-heading {
  margin-bottom: 15px;
}

.our-product-section .card {
  border-radius: 0px;
  border: 0px;
  background-color: transparent;
}

.our-product-section .card .card-body h6 {
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin-top: 5px;
  text-align: center;
}

.form-the-blog {
  padding: 30px 0px;
  position: relative;
}

.form-the-blog .swiper .swiper-button-prev {
  left: 10% !important;
}

.form-the-blog .swiper .swiper-button-next {
  right: 10% !important;
}

.form-the-blog a {
  text-decoration: none;
}

.form-the-blog .form-blog-heading {
  margin-bottom: 15px;
}

.form-the-blog .card {
  border-radius: 0px;
  border: 0px;
}

.form-the-blog .card .blog-images {
  height: 200px;
}

.form-the-blog .card .blog-images img {
  height: 100%;
  width: 100%;
  /* object-fit: contain; */
  height: 190px;
}

.form-the-blog .card .card-body {
  padding: 10px 0px;
}

.form-the-blog .card .card-body h6 {
  font-size: 16px;
  text-align: left;
  color: rgb(0, 0, 0);
}

.form-the-blog .date-time {
  font-size: 12px;
  color: rgb(85, 85, 85);
  font-weight: 400;
}

.form-the-blog .card .card-body p {
  font-size: 15px;
  color: rgb(85, 85, 85);
  font-weight: 400;
  margin-bottom: 10px;
}

.form-the-blog .card .card-body .btn-primary {
  background-color: #7cb9e8;
  border: none;
  color: #fff;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.8;
  padding: 10px;
  border-radius: 0px;
}

@media screen and (max-width: 1440px) {
  .form-the-blog .swiper .swiper-button-prev {
    left: 0% !important;
  }

  .form-the-blog .swiper .swiper-button-next {
    right: 0% !important;
  }
}

@media screen and (max-width: 1200px) {
  .form-the-blog .swiper .swiper-button-prev {
    left: 2% !important;
  }

  .form-the-blog .swiper .swiper-button-next {
    right: 2% !important;
  }
}

.instragram-follow {
  background-color: #1da827;
  padding: 15px;
}

.instragram-follow .follow-heading h3 {
  color: #fff;
  text-align: center;
  font-size: 40px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 800;
}

.instragram-follow .follow-heading h3 a {
  color: #fff;
  font-size: 40px;
  text-transform: capitalize;
}

.follow-instagram-section {
  position: relative;
}

.follow-instagram-section .swiper .swiper-button-prev {
  left: 10% !important;
}

.follow-instagram-section .swiper .swiper-button-next {
  right: 10% !important;
}

.follow-instagram-section .card {
  border-radius: 0px;
  border: 0px;
}

.follow-instagram-section .card img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1440px) {
  .follow-instagram-section .swiper .swiper-button-prev {
    left: 0% !important;
  }

  .follow-instagram-section .swiper .swiper-button-next {
    right: 0% !important;
  }
}

@media screen and (max-width: 1200px) {
  .follow-instagram-section .swiper .swiper-button-prev {
    left: 2% !important;
  }

  .follow-instagram-section .swiper .swiper-button-next {
    right: 2% !important;
  }
}

@media only screen and (max-width: 991px) {
  .follow-instagram-section .card {
    margin-bottom: 20px;
  }

  .instragram-follow .follow-heading h3 {
    font-size: 30px;
  }

  .instragram-follow .follow-heading h3 a {
    font-size: 30px;
  }
}

/* approach-section */

.approach-section {
  padding: 20px 0px;
}

.approach-section .home-patine-banner {
  background-image: url(../../image/doctor-new.jpg);
  /* background-color: #69A9A9; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 113px;
  border-radius: 6px;
  height: 600px;
}

.home-patine-banner .row {
  align-items: center;
  height: 100%;
}

.approach-section .home-patine-banner h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 600;
  line-height: 45px;
  position: relative;
  margin-bottom: 56px;
}

.approach-section .home-patine-banner h3::after {
  content: "";
  position: absolute;
  width: 88px;
  height: 5px;
  background: #fff;
  left: 0;
  border: 0;
  bottom: -20px;
}

.approach-section .home-partice-button {
  background: #1da827;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  padding: 5px 21px;
  border-radius: 0px;
}

.approach-section .play-button {
  height: 200px;
  width: 200px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 25px;
  text-transform: capitalize;
  background: #7ca2a2;
  color: #fff;
}

.approach-section .home-patine-banner p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 47px;
}

.approach-section .btn {
  box-shadow: none;
}

.approach-section .modal-body {
  position: relative;
  border: 0px;
  padding: 0px;
}

.approach-section .close-button {
  position: absolute;
  top: -6px;
  z-index: 1;
  right: -4px;
  background: #1da827;
  border-radius: 72px;
  padding: 4px 8px;
  border: 0px;
}

@media only screen and (max-width: 991px) {
  .play-button-row {
    text-align: center;
  }

  .approach-section .home-patine-banner {
    padding: 20px;
  }

  .approach-section .home-patine-banner p {
    margin-bottom: 30px;
  }

  .approach-section .home-patine-banner h3 {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 34px;
  }
}

/* featured-product-section */

.featured-product-section {
  padding: 30px 0px;
  position: relative;
}
.featured-product-section .spinner-border {
  position: inherit;
}

.featured-product-section .swiper .swiper-button-prev {
  left: 10% !important;
  top: 63%;
}

.featured-product-section .swiper .swiper-button-next {
  right: 10% !important;
  top: 63%;
}

.featured-product-section .feature-product-heading {
  margin-bottom: 15px;
}

.featured-product-section .feature-product-heading h3 {
  margin-bottom: 0px;
}

.featured-product-section .nav-pills {
  margin-bottom: 30px;
}

.featured-product-section .nav-pills .nav-item .nav-link {
  background: #f6f6f6;
  color: #000;
  text-align: center;
  border-radius: 0px;
  padding: 8px 40px;
}

.featured-product-section .nav-pills .nav-item .active {
  background: #1da827;
  color: #fff;
}

.featured-product-section .nav-pills .nav-item {
  margin-right: 40px;
}

.featured-product-section .nav-pills .nav-item:last-child {
  margin-right: 0px;
}

.featured-product-section .card {
  border-radius: 0px;
  border: 0px;
}

.featured-product-section .card ins {
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
}

.featured-product-section .card .card-body p {
  margin-top: 5px;
}

.featured-product-section .card .card-body p span {
  background-color: #1da827;
  color: white;
  border-radius: 12px;
  font-size: 9px;
  font-weight: 400;
}

.featured-product-section .card .card-body .buy-now-button {
  background: rgb(29, 168, 39);
  color: white;
  border-radius: 0px;
  font-size: 12px;
  border: 0px;
}

.featured-product-section .card .card-body .add-to-cart {
  background: #6bb7d7;
  color: white;
  border-radius: 0px;
  font-size: 12px;
  border: 0px;
}

.featured-product-section .card .card-body .add-to-card-button-group {
  gap: 10px;
  display: flex;
  margin-top: 20px;
}

@media screen and (max-width: 1440px) {
  .featured-product-section .nav-pills .nav-item .nav-link {
    padding: 8px 40px;
  }

  .featured-product-section .swiper .swiper-button-prev {
    left: 0% !important;
  }

  .featured-product-section .swiper .swiper-button-next {
    right: 0% !important;
  }
}

@media only screen and (max-width: 1335px) {
  .featured-product-section .nav-pills .nav-item {
    margin-right: 10px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .featured-product-section .nav-pills .nav-item .nav-link {
    padding: 8px 30px;
  }

  .featured-product-section .swiper .swiper-button-prev {
    left: 2% !important;
  }

  .featured-product-section .swiper .swiper-button-next {
    right: 2% !important;
  }
}

@media only screen and (max-width: 991px) {
  .featured-product-section .nav-pills .nav-item .nav-link {
    padding: 8px 23px;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-section .flex-shrink-0 img {
    height: 290px;
    width: 170px;
    object-fit: contain;
  }
}

/* footer */
footer {
  background-color: #0942aa;
  padding-top: 60px;
}

.footer-logo-top {
  display: inline-block;
}

footer .support-box ul li {
  display: flex;
  gap: 15px;
  margin-bottom: 18px;
}

footer .support-box ul {
  border: 1.9px solid #fff;
  padding: 20px 25px;
}

footer .support-box ul p {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 30px;
  text-transform: uppercase;
  color: #ffffff;
}

footer .support-box ul li span {
  font-size: 15px;
  color: #fff;
}

footer .footer-logo-list ul {
  border: 1px solid#fff;
  padding: 23px;
  text-align: center;
  background-color: #fff;
}

footer .footer-logo-list ul li {
  margin-right: 50px !important;
  /* margin-bottom: 10px; */
}

footer .footer-logo-list ul li a {
  text-decoration: none;
}

footer .footer-logo-list ul li:last-child {
  margin-right: 0px !important;
}

footer .footer-logo-list p {
  color: #fff;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 0px;
}

.btn-success {
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 14px;
  padding: 15px 48px;
}

.btn-success:hover {
  border: 1px solid #fff;
}

footer .footer-link h6 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 15px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: bold;
}

footer .footer-link ul {
  margin-bottom: 0px;
}

footer .footer-link ul li {
  margin-bottom: 8px;
}

footer .footer-link ul li a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

footer .foottextwhite {
  font-size: 18px;
}

footer .footinputwhite {
  color: #fff;
  border: solid 1px #fff;
  background: transparent;
  font-size: 12px;
  padding: 12px 20px;
  text-transform: uppercase;
}

footer .footsubmit {
  border-radius: inherit;
  border: solid 1px #fff;
  border-left: 0px;
  background: #1da827;
  font-size: 12px;
  padding: 12px 20px;
  color: #fff;
  text-transform: uppercase;
}

footer .btn-success {
  text-transform: uppercase;
  padding: 12px 35px;
}

footer .download-button {
  text-align: end;
  margin-top: 20px;
}

.copy-right-area p {
  margin-bottom: 0px;
}

footer .copy-right-area {
  text-align: center;
  padding: 30px 0;
  border-top: 1.9px solid #ffff;
  margin-top: 40px;
}

footer .copy-right-content p a {
  color: #fff;
}

footer .copy-right-area .copy-right-row {
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.right-content {
  width: 75%;
}

@media only screen and (max-width: 1440px) {
  footer {
    padding-top: 50px;
  }

  footer .footer-link ul li a,
  footer .support-box ul li span {
    font-size: 12px;
  }

  footer .support-box ul li {
    margin-bottom: 10px;
  }

  footer .support-box ul li:last-child {
    margin-bottom: 0px;
  }

  footer .copy-right-area {
    padding: 15px 0;
  }

  footer .copy-right-area {
    margin-top: 25px;
  }

  .right-content {
    width: 84%;
  }

  footer .footer-logo-list ul {
    margin-bottom: 0;
  }

  footer .footer-logo-list ul li {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1107px) {
  footer .footer-logo-list ul li {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 991px) {
  footer .footer-link-col {
    margin-top: 40px;
  }

  footer .footer-logo-list ul li {
    margin-right: 50px !important;
  }

  .right-content {
    width: 77%;
  }
}

/* @media only screen and (max-width: 1238px) {
  footer .footer-logo-list ul {
    width: 866px;
  }
} */

@media only screen and (max-width: 767px) {
  footer .copy-right-area .copy-right-row {
    display: block;
  }

  .footer-logo-top {
    margin-bottom: 40px;
  }

  footer .footer-link ul {
    margin-bottom: 20px;
  }

  footer .btn-success {
    margin-bottom: 20px;
  }

  footer .support-box ul {
    margin-bottom: 30px;
  }

  footer .download-button {
    text-align: start;
    margin-top: 20px !important;
  }

  .right-content {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  footer .footer-logo-list ul {
    display: flex;
    justify-content: space-between;
  }
  footer .footer-logo-list ul li {
    margin-right: auto !important;
    margin-bottom: 0;
  }
  footer .footer-logo-list ul li img {
    width: 40px;
    height: 40px;
  }
}

.search-swiper-slider {
  position: relative;
}

.search-swiper-slider .swiper .swiper-button-prev {
  left: 10% !important;
  top: 44% !important;
}

.search-swiper-slider .swiper .swiper-button-next {
  right: 10% !important;
  top: 44% !important;
}

@media screen and (max-width: 1440px) {
  .search-swiper-slider .swiper .swiper-button-prev {
    left: 0% !important;
  }

  .search-swiper-slider .swiper .swiper-button-next {
    right: 0% !important;
  }
}

@media screen and (max-width: 1200px) {
  .search-swiper-slider .swiper .swiper-button-prev {
    left: 2% !important;
  }

  .search-swiper-slider .swiper .swiper-button-next {
    right: 2% !important;
  }
}

.swiper {
  position: inherit !important;
}

.swiper .swiper-button-prev {
  background-image: url(../../image/angle-left-solid.svg);
  background-position: center;
  height: 38px;
  width: 38px;
  background-size: 12px;
}

.swiper .swiper-button-prev::after {
  font-size: 0px;
}

.swiper .swiper-button-next {
  background-image: url(../../image/right-arrow.svg);
  background-position: center;
  height: 38px;
  width: 38px;
  background-size: 12px;
}

.swiper .swiper-button-next::after {
  font-size: 0px;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 30px;
  background-repeat: no-repeat;
  background-color: #7cb9e8;
  z-index: 9999;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #000;
}

.swiper-button-prev {
  left: 0px !important;
  z-index: 9999;
}

.swiper-button-next {
  right: 0px !important;
  z-index: 9999;
}

@media only screen and (max-width: 450px) {
  .search-swiper-slider .card img {
    width: 250px;
  }
}

.ailment-heading{
  font-size: 28px;
}
@media only screen and (max-width: 450px) {
  .ailment-heading{
    font-size: 23.2864px;
  }
}
