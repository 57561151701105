/* General skeleton styles */

.article-skeleton {
.skeleton-img, .skeleton-title, .skeleton-input {
  background-color: #e0e0e0; /* Light gray */
  border-radius: 4px;
  animation: shimmer 1.5s infinite;
}

/* Image skeleton */
.skeleton-img {
  width: 400px;
  height: 350px;
  border-radius: 20px;
}

/* Title skeleton */
.skeleton-title {
  width: 60%;
  height: 24px;
  margin-top: 16px;
  margin-left: 20px;
}

/* Input skeleton */
.skeleton-input {
  width: 80%;
  height: 40px;
  margin-top: 16px;
  margin-left: 50px;
  border-radius: 4px;
}

/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
}